import React from "react";
import { Button } from "react-bootstrap";

interface Props {
  children?: React.ReactNode;
  onClick: () => void;
}
const RegistrationBtn: React.FC<Props> = ({ children, onClick }) => {
  return (
    <div>
      <Button
        type="submit"
        onClick={onClick}
        variant="outline-primary account-btn btn-X"
      >
        {children}
      </Button>
    </div>
  );
};

export default RegistrationBtn;
