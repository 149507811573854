import MailchimpForm from "../Components/Footer/MailchimpForm";
import Header from "../Components/Header/Header";
import Games from "../Pages/Games/Games";

const Layout = () => {
  return (
    <div>
      <Header />
      <div className="main-wrapper pb-5">
        <Games />
      </div>
      <MailchimpForm />
    </div>
  );
};

export default Layout;
