// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./Components/Fonts/Bangers-Regular.ttf", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("./Components/Fonts/Montserrat-Regular.ttf", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face {\r\n  font-family: \"Bangers\";\r\n  font-weight: 900;\r\n  src: local(\"Bangers\"),\r\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ") format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Montserrat-Regular\";\r\n  font-weight: 500;\r\n  src: local(\"Bangers\"),\r\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format(\"truetype\");\r\n}\r\n\r\n/* video player css */\r\n.ytp-gradient-top,\r\n.ytp-chrome-top {\r\n  display: none !important;\r\n}\r\n.ytp-hide-controls:not(.ytp-mweb-player) .ytp-watermark {\r\n  display: none !important;\r\n}\r\n.html5-video-player a {\r\n  display: none !important;\r\n}\r\n* {\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n", "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,gBAAgB;EAChB;8DACgE;AAClE;;AAEA;EACE,iCAAiC;EACjC,gBAAgB;EAChB;8DACmE;AACrE;;AAEA,qBAAqB;AACrB;;EAEE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,wBAAwB;AAC1B;AACA;EACE,SAAS;EACT,UAAU;AACZ","sourcesContent":["@font-face {\r\n  font-family: \"Bangers\";\r\n  font-weight: 900;\r\n  src: local(\"Bangers\"),\r\n    url(./Components/Fonts/Bangers-Regular.ttf) format(\"truetype\");\r\n}\r\n\r\n@font-face {\r\n  font-family: \"Montserrat-Regular\";\r\n  font-weight: 500;\r\n  src: local(\"Bangers\"),\r\n    url(./Components/Fonts/Montserrat-Regular.ttf) format(\"truetype\");\r\n}\r\n\r\n/* video player css */\r\n.ytp-gradient-top,\r\n.ytp-chrome-top {\r\n  display: none !important;\r\n}\r\n.ytp-hide-controls:not(.ytp-mweb-player) .ytp-watermark {\r\n  display: none !important;\r\n}\r\n.html5-video-player a {\r\n  display: none !important;\r\n}\r\n* {\r\n  margin: 0;\r\n  padding: 0;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
