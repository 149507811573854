import setting from "./settings";

export default (() => {
  return {
    // Basic Auth
    // Authentication API
    registration: `${setting.api.url}registerEmail`,
    login: `${setting.api.url}loginEmail`,
    changePassword: `${setting.api.url}changePassword`,
    forgetPassword: `${setting.api.url}forgetPasswordLink`,
    resetPassword: `${setting.api.url}resetPassword`,
    // Social Login API
    googleLogin: `${setting.api.url}googleOauthLogin`,
    facebookLogin: `${setting.api.url}facebookOauthLogin`,

    // Home page ==> Game Page All APIs
    allGames: `${setting.api.url}all_games`,
    allTrophies: `${setting.api.url}trophies`,
    aboutTrophy: `${setting.api.url}trophyInfo`,

    // Quiz game - Quiz API
    quizGame: `${setting.api.url}games`,
    liveScore: `${setting.api.url}live-score`,
    getLiveScore: `${setting.api.url}live-score`,
    // multipalyer game
    multipayerGame: `${setting.api.url}multi_player_game`,
    singlePlayerGame: `${setting.api.url}single_player_game`,
    featuredGame: `${setting.api.url}get_featured_game`,
    // Leadersboard API
    allTimeLeadersboard: `${setting.api.url}leaderboardAlltime`,
    monthlyLeadersboard: `${setting.api.url}leaderboardMonthly`,
    weeklyLeadersboard: `${setting.api.url}leaderboardWeekly`,
    todayLeadersboard: `${setting.api.url}leaderboardToday`,

    //User Rank in Leadersboards Section for day, week, monthly, all TIme
    userRankToday: `${setting.api.url}leaderboardUserRankToday`,
    userRankWeekly: `${setting.api.url}leaderboardUserRankWeekly`,
    userRankMonthly: `${setting.api.url}leaderboardUserRankMonthly`,
    userRankAllTime: `${setting.api.url}leaderboardUserRankAlltime`,
    // final results page data >> data sent to backend
    gameData: `${setting.api.url}userGamePlayedData`,
    userGameData: `${setting.api.url}particular_user_played_stats`,
    userGameCardsData: `${setting.api.url}user_games`,
    userTrophyCardsData: `${setting.api.url}user_trophies`,
    earnedTrophies: `${setting.api.url}user_trophy_won_details`,
    allUserStats: `${setting.api.url}all_user_played_stats`,

    // user profilr services
    changeUserImage: `${setting.api.url}changeProfilePicture`,
    userProfileData: `${setting.api.url}userProfile`,
  };
})();
