import React, { useEffect, useState } from "react";
import LeadersboardCard from "./LeadersboardCard";
import MultiplayerCard from "./MultiplayerCard";
import { featuredGameFun } from "../../Services/quizGameServices";
import { Button, Modal } from "react-bootstrap";
import QuizGame from "../../QuizGame/SinglePlayer/QuizGame";
import fivepoints from "../../assets/images/round1-redpoints.png";
import tenpoints from "../../assets/images/round2-bluepoints.png";
import fifteenpoints from "../../assets/images/round3-redpoints.png";
import TrophiesEarned from "./TrophiesEarned";

const MainCard = () => {
  const [bgmusicState, setBgMusicState] = useState(true);

  // game modal states and funcitonalities
  const [show, setShow] = useState(false);
  const handleClose = () => {
    setShow(false);
    setBgMusicState(false);
  };

  const Mailto = ({ email, subject, children }) => {
    return (
      <a href={`mailto:${email}?subject=${encodeURIComponent(subject)}`}>
        {children}
      </a>
    );
  };
  function scrolldiv() {
    var elem = document.getElementById("game-card-wrapper");
    elem?.scrollIntoView();
  }
  return (
    <div className="games_maincard py-5 px-md-4 px-3 align-items-center d-flex justify-content-around row">
      {/* removed class 'mainpage-card' to apply the new design as of 6/march/24 */}
      <div className="d-flex col-md-10 align-items-center justify-content-between px-lg-5 px-md-4 px-sm-3 px-3">
        <div
          className="col-9 d-flex flex-column align-items-center"
          style={{ width: "60%" }}
        >
          <h3 className="main-heading ms-0 text-center">PB QUIZ WIZ GAME!</h3>
          <p className="mt-sm-4 mt-3 text-center">
            Have fun playing our PB Quiz Games!! Enjoy answering trivia
            questions from a wide range of categories!
          </p>
          <div className="host-btn d-flex gap-4 mt-sm-3 mt-0">
            <Button
              className="outline-primary px-20 mb-3 featuredgamebtn"
              onClick={() => scrolldiv()}
            >
              Play now
            </Button>
          </div>
        </div>
        <div
          className="col-3 d-flex flex-column justify-content-center"
          style={{ width: "20%" }}
        >
          <img
            className="float-right ms-auto points-img"
            src={fifteenpoints}
            alt=""
            width="83px"
          />
          <img className="points-img" src={tenpoints} alt="" width="88px" />
          <img
            className="float-right ms-auto points-img"
            src={fivepoints}
            width="83px"
            alt=""
          />
        </div>
      </div>
      {/* commented below section to apply the new design as of 6/march/24 */}
      {/* <div className="card-wrapper col-lg-3 col-md-5 col-sm-6 col-12 mt-4 mt-md-0 p-0">
        <LeadersboardCard />
      </div>
      <div className="row d-flex justify-content-around mt-lg-4 mt-md-3 mt-sm-2 mt-2 px-md-4 px-3 p-0 maincard-gameoptions ">
        <div className="col-md-4 col-12 d-flex p-0 my-1 align-items-center justify-content-center">
          <MultiplayerCard />
        </div>
        <div className="host-game px-lg-5 px-md-4 px-sm-3 px-2 col-md-4 col-12">
          <h6 className="heading mt-3 text-dark"> Host A Game</h6>
          <p className="text-dark">Want to host a game? You can!</p>
          <Button variant="outline-primary btn-sec game-btn mb-3">
            <Mailto
              email="pb@thepbnetwork.com"
              subject="Host a Game with the PB Network!"
            >
              Host A Game!
            </Mailto>
          </Button>
        </div>
        <div className="host-game px-lg-5 px-md-4 px-sm-3 px-2 col-md-4 col-12">
          <h6 className="text-dark mt-3">Trophies Earned!</h6>
          <TrophiesEarned />
        </div>
      </div> */}
    </div>
  );
};

export default MainCard;
