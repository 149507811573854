import React from "react";
import ReactPlayer from "react-player";
import VideoPlayer from "../../../Components/Player";

const SecondRoundSnippet = ({ roundTwoVideo, nextComponent }) => {
  const roundTwoQuiz = () => {
    nextComponent("roundTwo");
  };
  return (
    <div className="round-snippet d-flex flex-column align-items-center">
      <div className="player-wrapper">
        <VideoPlayer
          loop={false}
          url={roundTwoVideo}
          onEnded={roundTwoQuiz}
        />
      </div>
      <h1 className="w-100">Round 2</h1>
    </div>
  );
};

export default SecondRoundSnippet;
