import Api from "../config/Api";
import ApiInstance from "../config/Intercepter";

export function allGamesFun() {
  return ApiInstance.get(`${Api.allGames}`);
}
export function allTrophiesFun() {
  return ApiInstance.get(`${Api.allTrophies}`);
}

export function aboutTrophyFun(id: any) {
  return ApiInstance.get(`${Api.aboutTrophy}/${id}`);
}
