import React from "react";
import MailchimpSubscribe from "react-mailchimp-subscribe";
import settings from "../../config/settings";
import Footer from "./Footer";

const MailchimpForm = () => {
  const postUrl = `https://thepbnetwork.us6.list-manage.com/subscribe/post?u=${settings.api.REACT_APP_MAILCHIMP_U}&amp;id=${settings.api.REACT_APP_MAILCHIMP_ID}`;

  return (
    <div className="mc_embed_signup">
      <MailchimpSubscribe
        url={postUrl}
        render={({ subscribe, status, message }) => (
          <Footer
            status={status}
            message={message}
            onValidated={(formData) => subscribe(formData)}
          />
        )}
      />
    </div>
  );
};

export default MailchimpForm;
