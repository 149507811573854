import { useState, useEffect } from "react";
import Loader from "../../assets/Loader/Loader";
import star from "../../assets/icons/starsvg.svg";
import {
  singlePlayerGameFun,
  userGameCardsDataFun
} from "../../Services/quizGameServices";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";

const GameCard = () => {
  // All Games states along with id and type of the game
  const reduxState = useSelector((state: any) => state);
  const navigate = useNavigate();

  // game modal states and funcitonalities
  const openGame = (id: string, host: string) => {
    // navigate(`/play-game?by=${host.replace(" ", "").trim()}&gameId=${id}`);
    navigate(`/play-game/gameId/${id}`);
  };

  // all single player games in main page api
  const { isLoading, data } = useQuery("products", singlePlayerGameFun);

  // redux to update stats and data after login or after playing the game
  useEffect(() => {
    if (
      reduxState.userReducer.isLogin ||
      reduxState.finalResult.finalResultState
    ) {
      userGameCardInfo();
    }
  }, [reduxState.userReducer.isLogin, reduxState.finalResult.finalResultState]);

  useEffect(() => {
    if (!reduxState.userReducer.isLogin) {
      setGameCardData([]);
    }
  }, [reduxState.userReducer.isLogin]);

  // get game cardd inf0
  const [gameCardData, setGameCardData] = useState([]);

  const userGameCardInfo = () => {
    userGameCardsDataFun().then((res: any) => {
      setGameCardData(res.data.data);
    });
  };

  //  DOM return
  return (
    <div className="container allgames-wrapper" id="game-card-wrapper">
      <div className="main-heading mt-5 mb-2 ">GAMES</div>
      {isLoading ? <Loader /> : null}
      {/* slider that contains all games */}
      {!isLoading && data?.data?.data?.length === 0 && (
        <h6 className="hostname text-center">No Games Found</h6>
      )}
      {!isLoading && (
        <div
          className="d-flex align-items-center justify-content-center"
          style={{ flexWrap: "wrap" }}
        >
          {data?.data?.data?.map((item: any, key: any) => {
            return (
              <div
                key={item.id}
                className="d-flex flex-column align-items-center ms-0"
                style={{ height: "280px" }}
              >
                <div className="card text-white sec-card allgame-card">
                  <img
                    key={key + 1}
                    src={item.image}
                    onClick={() => openGame(item.id, item.host)}
                    className="card-img"
                    alt="..."
                  />
                  {gameCardData.map((gameData: any, key: any) => {
                    return (
                      <div key={key + 1}>
                        {item.id === gameData.game_id &&
                          gameData.star_won === 0 && (
                            <p className="half-text">
                              {gameData.correct_answer}/
                              {gameData.total_questions}
                            </p>
                          )}

                        {item.id === gameData.game_id &&
                          gameData.star_won === 1 && (
                            <img
                              src={star}
                              alt="star_icon"
                              className="star-logo"
                            />
                          )}
                      </div>
                    );
                  })}
                </div>
                <div>
                  <h5 className="image-title text-center gamename">
                    {item.name}
                  </h5>
                  <h6 className="hostname text-center">Host: {item.host}</h6>
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default GameCard;
