import Api from "../config/Api";
import ApiInstance from "../config/Intercepter";

// open Game on Ckick
export function changeProfileImage(payload: any) {
  return ApiInstance.post(`${Api.changeUserImage}`, payload);
}
export function viewprofileData() {
  return ApiInstance.get(`${Api.userProfileData}`);
}
