import { Route, Routes } from "react-router-dom";
import ChangePassword from "./Components/Auth/ChangePassword";
import ForgotPassword from "./Components/Auth/forgotPassword";
import ResetPassword from "./Components/Auth/resetPassword";
import PublicRoutes from "./Routes/PublicRoutes";
import Path from "./Routes/RouterConstants";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import settings from "./config/settings";
import { QueryClientProvider, QueryClient } from "react-query";

const queryClient = new QueryClient();

function App() {
  let location = useLocation();
  useEffect(() => {
    window.gtag("config", settings.api.GOOGLE_ANALYTICS_ID, {
      page_title: location.pathname,
      page_path: location.pathname,
    });
  }, [location]);

  return (
    <div className="App">
      <QueryClientProvider client={queryClient}>
        <Routes>
          <Route path={Path.changePassword} element={<ChangePassword />} />
          <Route path={Path.forgotPassword} element={<ForgotPassword />} />
          <Route path={Path.resetPassword} element={<ResetPassword />} />
        </Routes>
        <PublicRoutes />
      </QueryClientProvider>
    </div>
  );
}

export default App;
