import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import Path from "../../Routes/RouterConstants";
import Logo from "../../assets/icons/Logo.png";
import Login from "../Auth/Login";

const Header = () => {
  // fix header on scroll
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  /* Method that will fix header after a specific scrollable */
  const isSticky = (e: any) => {
    const header = document.querySelector(".header");
    const scrollTop = window.scrollY;
    scrollTop >= 50
      ? header?.classList.add("is-sticky")
      : header?.classList.remove("is-sticky");
  };
  // const userId = localStorage.getItem("userid");
  return (
    <div>
      <h6 className="header-text">
        THE PLACE FOR PEOPLE OF ALL ABILITIES TO SHARE, PLAY, AND ENTERTAIN!
      </h6>
      <header className="header">
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container-fluid menu-toggle m-0 p-0 ">
            <div className="action-btns d-none">
              <Login />
              <button
                className="navbar-toggler me-md-4 me-0"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#navbarTogglerDemo01"
                aria-controls="navbarTogglerDemo01"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="ms-md-4 ms-2">
              <a href="https://thepbnetwork.com/">
                <img
                  className="logo-image"
                  src={Logo}
                  alt=""
                  height={50}
                  width={50}
                />
              </a>
            </div>
            <div className="collapse navbar-collapse" id="navbarTogglerDemo01">
              <ul className="navbar-nav nav-menu">
                <li>
                  <a href="https://thepbnetwork.com/pat-barneys-quiz-wiz/">
                    Game Shows
                  </a>
                </li>
                <li>
                  <a href="https://thepbnetwork.com/tv-shows/">TV Shows</a>
                </li>
                <li>
                  <NavLink end to={Path.games}>
                    Games
                  </NavLink>
                </li>
                <li>
                  <a href="https://thepbnetwork.com/stories/">Stories</a>
                </li>
                <li>
                  <a href="https://thepbnetwork.com/programs/">Programs</a>
                </li>
                <li>
                  <a href="https://thepbnetwork.com/about/">About</a>
                </li>
              </ul>
            </div>
            <div className="account-info d-flex gap-2 me-4">
              <Login />
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
