import React, { useState } from "react";
// Types
import { AnsObj } from "../QuizGame";
import { Wrapper, ButtonWrapper } from "./QuestionCard.styles";
// icons
import wrongIcon from "../../../assets/icons/wrong.svg";
import checkIcon from "../../../assets/icons/check.svg";

export type Props = {
  question: string;
  answers: string[];
  callback: (e: any) => void;
  userAnswer: AnsObj | undefined;
  btnDisable?: any;
};

function QuestionCard({ question, answers, callback, userAnswer, btnDisable }) {
  return (
    <Wrapper>
      <p
        className="question-tag"
        dangerouslySetInnerHTML={{ __html: question }}
      />

      <div>
        {answers.map((answer: any, key: any) => (
          <ButtonWrapper
            className="options-wrapper"
            key={answer}
            correct={userAnswer?.correctAnswer === answer}
            userClicked={userAnswer?.answer === answer}
          >
            <button
              className={`text-start px-4 ${btnDisable ? "ans-button" : ""}`}
              disabled={userAnswer || btnDisable ? true : false}
              value={answer}
              onClick={callback}
            >
              <span className="d-flex justify-content-between text-white align-items-center">
                {(key + 10).toString(36).toUpperCase() + ". "} {answer}
                {userAnswer?.correctAnswer === answer && (
                  <img
                    className="answer-img text-white"
                    src={checkIcon}
                    alt=""
                  />
                )}
                {userAnswer?.correctAnswer !== answer && (
                  <img
                    className="answer-img text-white"
                    src={wrongIcon}
                    alt=""
                  />
                )}
              </span>
            </button>
          </ButtonWrapper>
        ))}
      </div>
    </Wrapper>
  );
}

export default QuestionCard;
