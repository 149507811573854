import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { ReactComponent as MuteIcon } from "../../assets/icons/mute.svg";
import { ReactComponent as UnMuteIcon } from "../../assets/icons/unmute.svg";
import Loader from "../../assets/icons/loading-video.gif";

type VideoConfig = {
  url: string;
  onEnded?: () => void;
  onReady?: () => void;
  loop?: boolean;
  playBtn?: boolean;
  hideBtn?: any;
  muteBtn?: boolean;
  unmuteBtn?: any;
};
const VideoPlayer: React.FC<VideoConfig> = ({
  url,
  onEnded,
  onReady,
  loop,
  playBtn,
  hideBtn,
  muteBtn,
  unmuteBtn,
}) => {
  const [deviceState, setDeviceState] = useState(false);
  const [buffering, setBuffering] = useState(true);
  const [iphoneDevice, setIphoneDevice] = useState(false);
  const [mute, setMute] = useState(true);

  const bufferStart = () => {};

  const bufferEnd = () => {
    setBuffering(false);
  };
  const setBtnFunc = () => {
    hideBtn(false);
    unmuteBtn(false);
  };

  useEffect(() => {
    const isIOS =
      "ontouchstart" in window ||
      (window.DocumentTouch && document instanceof window.DocumentTouch);

    const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);

    if (
      /iPad|iPhone|iPod/.test(navigator.userAgent) ||
      (isIOS &&
        isSafari &&
        /Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.userAgent))
    ) {
      // ios device
      setIphoneDevice(true);
      setDeviceState(false);
    } else if (
      /Macintosh|MacIntel|MacPPC|Mac68K/.test(navigator.userAgent) &&
      !window.MSStream
    ) {
      // apple device
      setDeviceState(true);
      setIphoneDevice(false);
    }
  }, []);

  console.log("iphoneDevice >", iphoneDevice, "deviceState > ", deviceState);

  return (
    <>
      {iphoneDevice && !deviceState && (
        <>
          <ReactPlayer
            className={`react-player ${
              buffering ? "bg-dark bg-gradient bg-opacity-50" : ""
            }`}
            style={{ position: "relative" }}
            url={url}
            pip={true}
            stopOnUnmount={false}
            onReady={onReady}
            onEnded={onEnded}
            onBuffer={bufferStart}
            onBufferEnd={bufferEnd}
            loop={loop}
            playing
            playsinline
            muted={mute}
            volume={mute ? 0 : 1}
            config={{
              vimeo: {
                playerOptions: {
                  playsinline: true,
                },
              },
            }}
          />
          {mute && !buffering ? (
            <MuteIcon className="mute-icon" onClick={() => setMute(false)} />
          ) : !mute && !buffering ? (
            <UnMuteIcon className="mute-icon" onClick={() => setMute(true)} />
          ) : null}
          {/* gitttttt */}
        </>
      )}

      {deviceState && !iphoneDevice && (
        <>
          <ReactPlayer
            className={`react-player ${
              buffering ? "bg-dark bg-gradient bg-opacity-50" : ""
            }`}
            style={{ position: "relative", pointerEvents: "auto" }}
            url={url}
            pip={true}
            stopOnUnmount={false}
            onReady={onReady}
            onEnded={onEnded}
            onBuffer={bufferStart}
            onBufferEnd={bufferEnd}
            loop={loop}
            playing={true}
            playinline={true}
            autopause={false}
            autoplay={true}
            light={playBtn}
            onClick={setBtnFunc}
            // muted={mute ? true : false}
            // background={true}
            muted={muteBtn}
            // volume={mute ? 0 : 1}
            config={{
              vimeo: {
                playerOptions: {
                  playsinline: true,
                  // background: true
                },
              },
            }}
          />
          {/* {muteBtn && !buffering ? (
            <MuteIcon className="mute-icon" onClick={() => unmuteBtn(false)} />
          ) : !muteBtn && !buffering ? (
            <UnMuteIcon className="mute-icon" onClick={() => unmuteBtn(true)} />
          ) : null} */}
          {/* {buffering && (
            <img
              className="vid-loader"
              src={Loader}
              alt="Loading..."
              width={60}
            />
          )} */}
        </>
      )}

      {!deviceState && !iphoneDevice && (
        <>
          <ReactPlayer
            className={`react-player ${
              buffering ? "bg-dark bg-gradient  bg-opacity-50" : ""
            }`}
            url={url}
            pip={true}
            stopOnUnmount={false}
            onReady={onReady}
            onEnded={onEnded}
            onBuffer={bufferStart}
            onBufferEnd={bufferEnd}
            autopause={false}
            playing={true}
            autoplay={true}
            light={playBtn}
            loop={loop}
            style={{ pointerEvents: "none" }}
            config={{
              vimeo: {
                playerOptions: {
                  playsinline: true,
                },
              },
            }}
          />
        </>
      )}
    </>
  );
};

export default VideoPlayer;
