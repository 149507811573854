import { combineReducers } from "redux";
import { finalResult } from "./finalResult.reducer";
import { userReducer } from "./userLogin.reducer";
import { userChatEvent } from "./userChatEvents";
// import { alert } from './alert.reducer';

const rootReducer = combineReducers({
  finalResult,
  userReducer,
  userChatEvent,
  // alert
});

export default rootReducer;
