import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Logo from "../../assets/icons/Logo.png";
import { ReactComponent as Eyeopen } from "../../assets/icons/Eye.svg";
import { ReactComponent as Eyeclose } from "../../assets/icons/Eyeclose.svg";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { changePasswordFun } from "../../Services/authServices";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";

const ChangePassword = () => {
  // password validation
  let password;
  const formSchema = Yup.object().shape({
    currentpassword: Yup.string()
      .required("Current Password is required")
      .min(8, "Password must be at least 8 characters"),
    password: Yup.string()
      .required("New Password is required")
      .min(8, "Password must be at least 8 characters"),
    confirmPwd: Yup.string()
      .required("Confirm Password is required")
      .min(8, "Password must be at least 8 characters")
      .oneOf([Yup.ref("password")], "Passwords does not match"),
  });
  // const formOptions = { resolver: yupResolver(formSchema) };
  // form validatioon
  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onTouched",
    resolver: yupResolver(formSchema),
  });
  password = watch("password", "");
  const [currentPasswordShown, setCurrentPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);

  const showCurrentPassword = () => {
    setCurrentPasswordShown(currentPasswordShown ? false : true);
  };
  const showNewPassword = () => {
    setNewPasswordShown(newPasswordShown ? false : true);
  };
  const showConfirmPassword = () => {
    setConfirmPasswordShown(confirmPasswordShown ? false : true);
  };
  const navigate = useNavigate();
  // API call to change password
  const onSubmit = (data: any) => {
    let params = {
      old_password: data.currentpassword,
      new_password: data.password,
    };
    changePasswordFun(params)
      .then((res: any) => {
        if (res.statusText === "OK") {
          toast.success("Password Changed successfully", {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
          setTimeout(() => {
            navigate("/");
          }, 2500);
        } else {
        }
      })
      .catch((error) => {
        toast.error(`${error.error}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      });
  };
  return (
    <div className="auth-wrapper align-items-center bg-grey">
      <div className="row text-center justify-content-center">
        <div className="cards1 mb-5 align-middle">
          <div className="card-body">
            <img src={Logo} alt="Logo" width={65} className="py-3" />
            <p className="card-title3 mt-3">Change Password</p>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3 row justify-content-center">
                <div className="col-sm-12">
                  <div className="input-group">
                    <input
                      type={currentPasswordShown ? "text" : "password"}
                      className={`form-control shadow-none ${
                        errors.currentpassword ? "is-invalid" : ""
                      }`}
                      placeholder="Current Password"
                      {...register("currentpassword", {
                        required: "Current Password is required",
                        minLength: {
                          value: 8,
                          message: "Password must have atleast 8 characters",
                        },
                      })}
                    />
                    <button
                      className="eyebtn"
                      type="button"
                      onClick={showCurrentPassword}
                    >
                      {currentPasswordShown ? <Eyeopen /> : <Eyeclose />}
                    </button>
                  </div>

                  {errors.currentpassword && (
                    <p className="text-danger text-start ms-3 mt-1 mb-0">
                      {errors.currentpassword?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-3 row justify-content-center">
                <div className="col-sm-12">
                  <div className="input-group">
                    <input
                      type={newPasswordShown ? "text" : "password"}
                      className={`form-control shadow-none ${
                        errors.password ? "is-invalid" : ""
                      }`}
                      placeholder="New Password"
                      {...register("password", {
                        required: "Set New Password",
                        minLength: {
                          value: 8,
                          message: "Password must have atleast 8 characters",
                        },
                      })}
                    />
                    <button
                      className="eyebtn"
                      type="button"
                      onClick={showNewPassword}
                    >
                      {newPasswordShown ? <Eyeopen /> : <Eyeclose />}
                    </button>
                  </div>
                  {errors.password && (
                    <p className="text-danger  text-start ms-3 mt-1  mb-0">
                      {errors.password?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mb-3 row justify-content-center">
                <div className="col-sm-12">
                  <div className="input-group">
                    <input
                      type={confirmPasswordShown ? "text" : "password"}
                      className={`form-control shadow-none ${
                        errors.confirmPwd ? "is-invalid" : ""
                      }`}
                      placeholder="Confirm New Password"
                      {...register("confirmPwd", {
                        required: "Confirm New Password",
                        minLength: {
                          value: 8,
                          message: "Please type your current password",
                        },
                      })}
                    />
                    <button
                      className="eyebtn"
                      type="button"
                      onClick={showConfirmPassword}
                    >
                      {confirmPasswordShown ? <Eyeopen /> : <Eyeclose />}
                    </button>
                  </div>
                  {errors.confirmPwd && (
                    <p className="text-danger  text-start ms-3 mt-1  mb-0">
                      {errors.confirmPwd?.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-4 mb-4 row justify-content-center">
                <div className="col-sm-12">
                  <Button type="submit" variant="outline-light auth-btn btn-y">
                    Change Password
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ChangePassword;
