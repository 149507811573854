import React from "react";
import GameCard from "../../Components/Cards/GameCard";
import MainCard from "../../Components/Cards/MainCard";
import TrophyCard from "../../Components/Cards/TrophyCard";

const Games = () => {
  return (
    <div className="container-fluid">
      <MainCard />
      <GameCard />
      {/* <TrophyCard /> */}
    </div>
  );
};

export default Games;
