import React, { useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import Logo from "../../assets/icons/Logo-Footer.svg";
import { ReactComponent as FbIcon } from "../../assets/icons/FbIcon.svg";
import { ReactComponent as YtIcon } from "../../assets/icons/YtIcon.svg";
import { ReactComponent as IgIcon } from "../../assets/icons/IgIcon.svg";

const Footer = ({ status, message, onValidated }) => {
  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");

  useEffect(() => {
    if (status === "success") {
      clearFields();
    }
  }, [status]);

  const clearFields = () => {
    setFirstName("");
    setEmail("");
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    email &&
      firstName &&
      email.indexOf("@") > -1 &&
      onValidated({
        EMAIL: email,
        FNAME: firstName,
      });
  };

  return (
    <div className="footer">
      <div className="container row m-0 py-md-5 py-sm-4 py-3 justify-content-around">
        <div
          className="col-lg-5 col-md-5 col-sm-12 d-flex flex-column align-items-center"
          id="mc_embed_signup"
        >
          <div className="main-heading footer-heading m-0">
            JOIN OUR EMAIL LIST
          </div>
          <Form
            onSubmit={handleSubmit}
            className="d-flex flex-column w-100 px-5 mt-4 footer-form validate mc__form"
            id="mc-embedded-subscribe-form"
            name="mc-embedded-subscribe-form"
            target="_blank"
          >
            {status === "sending" && (
              <div className="mc__alert mc__alert--sending">sending...</div>
            )}
            {status === "error" && (
              <div
                className="mc__alert mc__alert--error text-danger"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}
            {status === "success" && (
              <div
                className="mc__alert mc__alert--success"
                dangerouslySetInnerHTML={{ __html: message }}
              />
            )}

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlInput1"
              id="mc_embed_signup_scroll"
            >
              <Form.Control
                required
                size="lg"
                type="text"
                placeholder="Name"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Control
                required
                size="lg"
                type="email"
                value={email}
                placeholder="Email"
                onChange={(e) => setEmail(e.target.value)}
              />
            </Form.Group>
            <Button
              type="submit"
              variant="outline-dark btn-footer bg-yellow"
              className=" btn-X"
              value="Subscribe"
              name="subscribe"
              id="mc-embedded-subscribe"
            >
              SEND
            </Button>
          </Form>
        </div>

        <div className="col-lg-4 col-md-4 d-flex flex-column useful-links mt-5">
          <ul className="useful-list row pt-3 px-0">
            <div className="col-6">
              <a href="https://thepbnetwork.com/pat-barneys-quiz-wiz/">
                <li className="useful-link my-lg-4">Game Shows</li>
              </a>
              <a href="https://thepbnetwork.com/tv-shows/">
                <li className="useful-link my-lg-4">TV Shows</li>
              </a>
              <a href="https://thepbnetwork.com/stories/">
                <li className="useful-link my-lg-4">Stories</li>
              </a>
            </div>
            <div className="col-6">
              <a href="https://thepbnetwork.com/programs/">
                <li className="useful-link my-lg-4">Programs</li>
              </a>
              <a href="https://thepbnetwork.com/about/">
                <li className="useful-link my-lg-4">About</li>
              </a>
              <a href="https://thepbnetwork.com/contact/">
                <li className="useful-link my-lg-4">Contact</li>
              </a>
            </div>
          </ul>
        </div>
        <div className="social-wrapper flex-column col-lg-3 col-md-3 col-sm-12 d-flex align-items-center justify-content-around px-1">
          <div className="logo footer-logo m-lg-3 m-md-2 m-1">
            <a href="https://thepbnetwork.com/">
              <img src={Logo} alt="" height={50} width={50} />
            </a>
          </div>
          <div className="social-links d-flex gap-lg-3 gap-md-1">
            <a href="https://www.facebook.com/thepbnetwork" target="_blank">
              <div className="social-link">
                <FbIcon />
              </div>
            </a>
            <a
              href="https://www.youtube.com/channel/UCB2CabDt4wQ0GMDJJ-iVf2Q"
              target="_blank"
            >
              <div className="social-link">
                <YtIcon />
              </div>
            </a>
            <a href="https://www.instagram.com/thepbnetwork/" target="_blank">
              <div className="social-link">
                <IgIcon />
              </div>
            </a>
          </div>
          <div className="support-btn">
            <a href="https://thepbnetwork.com/support-us/">
              <Button
                variant="outline-light btn-sec btn-z bangers-font"
                style={{ fontSize: "22px", letterSpacing: "1.5px" }}
              >
                Support Us
              </Button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
