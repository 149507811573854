import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import { AnsObj, QuesState } from "../QuizGame";
import QuestionCard from "./QuestionCard";
import { liveScoreFun } from "../../../Services/quizGameServices";

import {
  getPersonName,
  getUserEmail,
  getUserId
} from "../../../helper/uitility";
import useSound from "use-sound";
import VideoPlayer from "../../../Components/Player";

const wrongAnsSound = require("../../../assets/sounds/wrongAnswer.mp3");
const correctAnsSound = require("../../../assets/sounds/correctAnswer.mp3");

const RoundTwoQues = ({
  roundTwoQues,
  timerFunction,
  scoreData,
  nextComponent,
  timerVideo,
  clearCustomTimer,
  roundTwoCorrect,
  roundTwoWrong,
  gameId,
  backgroundMusic,
  setShowTimer,
  setBgMusicState,
  bgmusicState
}) => {
  const [wrongAnsSoundFun] = useSound(wrongAnsSound);
  const [correctAnsSoundFun] = useSound(correctAnsSound);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestions] = useState<QuesState[]>([]);
  const [userAnswers, setUserAnswers] = useState<AnsObj[]>([]);
  const [number, setNumber] = useState(0);
  const [score, setScore] = useState(0);
  const [gameOver, setGameOver] = useState(true);
  const [timer, setTimer] = useState(0);
  const [quesTimer, setQuesTimer] = useState("");
  const [btnDisable, setBtnDisable] = useState(true);
  const [quesVideoState, setQuesVideoState] = useState(false);
  const [rightAnsCount, setRightAnsCount] = useState(0);
  const [wrongAnsCount, setWrongAnsCount] = useState(0);
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout>>(
    setTimeout(() => "", 1)
  );

  // get name, email, id of user
  var personId = getUserId();
  var personName = getPersonName();
  var personEmail = getUserEmail();

  // update live score
  const liveScoreUpdate = () => {
    let params = {
      user_id: personId,
      user_email: personEmail,
      user_name: personName,
      live_score: score,
      game_id: gameId
    };
    liveScoreFun(params).then((res: any) => {});
  };

  // quiz fetching for game
  const fetchQuiz = async (): Promise<QuesState[]> => {
    return roundTwoQues?.map((questions: QuesState) => ({
      ...questions,
      answers: [
        questions.option_one,
        questions.option_two,
        questions.option_three
      ]
    }));
  };

  const countdownTimer = () => {
    if (timer > 0) {
      const time = setTimeout(() => setTimer(timer - 1), 1000);
      setTimerId(time);
    } else {
      if (timer === 0) {
        nextQues();
      }
    }
  };
  useEffect(() => {
    countdownTimer();
  }, [timer]);

  const startQuiz = async () => {
    setLoading(true);
    setGameOver(false);
    const newQues = await fetchQuiz();
    setQuestions(newQues);
    setQuesTimer("start");
    setScore(0);
    setUserAnswers([]);
    setNumber(0);
    setLoading(false);
  };

  const correctAudio = () => {
    try {
      correctAnsSoundFun();
    } catch (error) {
      console.error("Error playing correct audio:", error);
    }
  };

  const incorrectAudio = () => {
    try {
      wrongAnsSoundFun();
    } catch (error) {
      console.error("Error playing incorrect audio:", error);
    }
  };

  const checkAnswer = (e: any) => {
    if (!gameOver) {
      // User's answer
      const answer = e.currentTarget.value;
      // Check answer against correct answer
      const correct = questions[number].correct_answer === answer;
      // stop backgroud music on user ans
      setBgMusicState(false);
      stopMusic();
      // Add score if answer is correct and play sound accordingly
      if (correct) {
        setScore((prv: any) => prv + 10);
        scoreData((prv: any) => prv + 10);
        correctAudio();
        setRightAnsCount((prv: any) => prv + 1);
        roundTwoCorrect((prv: any) => prv + 1);
      } else {
        incorrectAudio();
        setWrongAnsCount((prv: any) => prv + 1);
        roundTwoWrong((prv: any) => prv + 1);
      }
      liveScoreUpdate();
      clearTimeout(timerId);
      clearCustomTimer();
      setTimeout(() => {
        nextQues();
      }, 3000);
      // Save the answer in the array for user answers
      const answerObject = {
        question: questions[number].question,
        answer,
        correct,
        correctAnswer: questions[number].correct_answer
      };
      setUserAnswers((prev: any) => [...prev, answerObject]);
    }
  };
  // background music in ques video
  const [play, { stop }] = useSound(backgroundMusic, {
    loop: true,
    volume: 0.1
  });

  const playMusic = () => {
    setBgMusicState(true);
    play();
  };
  const stopMusic = () => {
    setBgMusicState(false);
    stop();
  };
  if (!bgmusicState) {
    stop();
  }
  // next ques
  const nextQues = () => {
    const nextQ = number + 1;
    if (nextQ === roundTwoQues.length) {
      nextComponent("roundThreeSnippet");
      setGameOver(true);
      setQuesTimer("stop");
      stopMusic();
    } else {
      setNumber(nextQ);
      if (nextQ) {
        setBtnDisable(true);
        setQuesVideoState(false);
        stopMusic();
      }
      if (timer == 0) {
        setNumber(nextQ);
      }
    }
  };
  const onVideoLoad = () => {
    setBtnDisable(false);
  };
  const quesVideoLoad = () => {
    setTimer(30);
    timerFunction(30);
    setShowTimer(true);
    playMusic();
  };
  const endTheVideo = () => {
    setQuesVideoState(true);
    setBgMusicState(true);
  };
  useEffect(() => {
    startQuiz();
  }, []);

  return (
    <div>
      {!loading && !gameOver && (
        <div className="d-flex flex-column align-items-center round-questions">
          {!quesVideoState && (
            <div className="player-wrapper">
              <VideoPlayer
                url={questions[number].video_code}
                onEnded={endTheVideo}
                onReady={onVideoLoad}
                loop={false}
              />
            </div>
          )}

          {quesVideoState && (
            <div className="player-wrapper">
              <VideoPlayer
                url={timerVideo}
                loop={true}
                onReady={quesVideoLoad}
              />
            </div>
          )}

          <QuestionCard
            question={questions[number].question}
            answers={questions[number].answers}
            userAnswer={userAnswers ? userAnswers[number] : undefined}
            callback={checkAnswer}
            btnDisable={btnDisable}
          />
        </div>
      )}
    </div>
  );
};

export default RoundTwoQues;
