import React, { useState } from "react";
// OAuth Login Features
import { NavLink } from "react-router-dom";
import { changeProfileImage } from "../../Services/userProfileServices";
import {
  userRankAllTimeFun,
  userRankMonthlyFun,
  userRankTodayFun,
  userRankWeeklyFun,
} from "../../Services/leadersboardServices";
import { userGameDataFun } from "../../Services/quizGameServices";
import { Input } from "reactstrap";
import userPic from "../../assets/images/userimage.png";
import loader from "../../assets/icons/loader.gif";
import { ReactComponent as CrossIcon } from "../../assets/icons/exit.svg";
import Path from "../../Routes/RouterConstants";
import { useForm } from "react-hook-form";
import { GoogleLogout } from "react-google-login";
import settings from "../../config/settings";
import { useSelector } from "react-redux";
import { useMutation, useQuery, useQueryClient } from "react-query";

type ProfileProps = {
  profileClose?: () => void;
  logout?: () => void;
  showLogoutButton?: boolean;
  getProfileData?: any;
};

const Profile: React.FC<ProfileProps> = ({
  profileClose,
  logout,
  showLogoutButton,
  getProfileData,
}) => {
  const reduxState = useSelector((state: any) => state);
  const userFullName = localStorage.getItem("name");
  // form validation
  const {
    register,
    formState: { errors },
  } = useForm();
  // get game stats API
  const { data: userGameData } = useQuery("userGameData", userGameDataFun);

  // user rank today
  const useTodayRankFun = (userid: number) => {
    return useQuery(["today-rank", userid], () => userRankTodayFun(userid));
  };

  const { data: userDataToday } = useTodayRankFun(
    reduxState.userReducer.userId
  );

  // user rank weekly
  const useWeekRankFun = (userid: number) => {
    return useQuery(["week-rank", userid], () => userRankWeeklyFun(userid));
  };

  const { data: userDataWeekly } = useWeekRankFun(
    reduxState.userReducer.userId
  );

  // user rank mothly
  const useMonthlyRankFun = (userid: number) => {
    return useQuery(["monthly-rank", userid], () => userRankMonthlyFun(userid));
  };

  const { data: userDataMonthly } = useMonthlyRankFun(
    reduxState.userReducer.userId
  );

  // user rank all time
  const useAllTimeRankFun = (userid: number) => {
    return useQuery(["alltime-rank", userid], () => userRankAllTimeFun(userid));
  };

  const { data: userDataAllTime } = useAllTimeRankFun(
    reduxState.userReducer.userId
  );

  // ================== upload profile picture ========================
  const useUpdateProfilePic = () => {
    const queryClient = useQueryClient();
    return useMutation(changeProfileImage, {
      onSuccess: (data) => {
        queryClient.setQueryData("getProfileData", data);
      },
    });
  };
  const { mutate: profilePicUpdate, isLoading } = useUpdateProfilePic();
  const [image, setImage] = useState({ preview: "", raw: "" });
  const handleChange = (e: any) => {
    if (e?.target?.files?.length) {
      setImage({
        preview: URL.createObjectURL(e?.target?.files[0]),
        raw: e?.target?.files[0],
      });
    }
    let formData = new FormData();
    formData.append("profile_picture", e?.target?.files[0]);
    profilePicUpdate(formData);
  };

  return (
    <div className="profile-card card pt-md-5 pt-3">
      <CrossIcon onClick={profileClose} className="close-profile" />
      <div className="rounded-top text-white d-flex flex-column align-items-center justify-content-center">
        <div className="profile-pic-wrapper">
          <div className="profile-pic-holder shadow-sm bg-body rounded">
            <label htmlFor="upload-button">
              <img
                id="profilePic"
                className="pic"
                alt="user_image"
                src={
                  getProfileData &&
                  (getProfileData !== undefined || "undefined")
                    ? getProfileData
                    : userPic
                }
                {...register("uploadedimage", {})}
                style={{ objectFit: "contain" }}
              />
            </label>
            <label htmlFor="upload-button" className="upload-file-block">
              <div className="text-center">
                <div className="text-uppercase">
                  Update <br /> Profile Photo
                </div>
              </div>
            </label>
            <div>
              {isLoading ? (
                <img
                  className="img-loader"
                  src={loader}
                  alt="Loading..."
                  width={75}
                />
              ) : null}
            </div>
            <Input
              className="uploadProfileInput d-none"
              type="file"
              name="profile_pic"
              id="upload-button"
              accept="image/*"
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="user-info-container">
          <h5 className="p-0 m-0 text-capitalize">{userFullName}</h5>
        </div>
      </div>
      <div className="user-profile-data d-flex justify-content-evenly align-items-center text-center">
        <div className="d-flex align-items-center justify-content-center flex-column gamestats-card">
          <div className="stats-card">
            <h6 className="mb-1 h5">
              {userGameData?.data?.data?.total_games_played}
            </h6>
          </div>
          <p className="mb-0 stats-text">Games Played</p>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column gamestats-card">
          <div className="stats-card">
            <h6 className="mb-1 h5">
              {userGameData?.data?.data?.total_trophies_won}
            </h6>
          </div>
          <p className="mb-0  stats-text">Trophies Won</p>
        </div>
        <div className="d-flex align-items-center justify-content-center flex-column gamestats-card">
          <div className="stats-card">
            <h6 className="mb-1 h5">
              {userGameData?.data?.data?.total_star_won}
            </h6>
          </div>
          <p className="mb-0  stats-text">Stars Won</p>
        </div>
      </div>
      <div className="row card-body p-4 mt-lg-5 mt-md-4 mt-3 d-flex justify-content-center flex-wrap">
        <div className="col-md-6 col-12 mb-2">
          <div className="card game-stats-card text-white">
            <div className="card-body">
              <h5> ACHIEVEMENTS</h5>
              <p>Total Score : {userGameData?.data?.data?.total_score} </p>
              <p className="m-0 p-0">
                Most Played Game : {userGameData?.data?.data?.most_played_game}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mb-2">
          <div className="card game-stats-card text-white">
            <div className="card-body">
              <h5> QUESTIONS</h5>
              <p>
                Total Questions Answered :
                {userGameData?.data?.data?.total_questions_answer}
              </p>
              <p>
                Total Correct :{userGameData?.data?.data?.total_correct_answer}
              </p>
              <p className="m-0 p-0">
                Total Incorrect :
                {userGameData?.data?.data?.total_incorrect_answer}
              </p>
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mb-2">
          <div className="card game-stats-card text-white">
            <div className="card-body">
              <h5> TODAY</h5>
              {userDataToday?.data?.data?.rank == undefined || 0 ? (
                <p>
                  No Data Available. <br /> Please Play games to save your Stats
                </p>
              ) : (
                <>
                  <p>Score : {userDataToday?.data?.data?.score} </p>
                  <p className="m-0 p-0">
                    Rank : {userDataToday?.data?.data?.rank}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mb-2">
          <div className="card game-stats-card text-white">
            <div className="card-body">
              <h5> WEEKLY</h5>
              {userDataWeekly?.data?.data?.rank == undefined || 0 ? (
                <p>
                  No Data Available. <br /> Please Play games to save your Stats
                </p>
              ) : (
                <>
                  <p>Score : {userDataWeekly?.data?.data?.score} </p>
                  <p className="m-0 p-0">
                    Rank : {userDataWeekly?.data?.data?.rank}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mb-2">
          <div className="card game-stats-card  text-white">
            <div className="card-body">
              <h5> MONTHLY</h5>
              {userDataMonthly?.data?.data?.rank == undefined || 0 ? (
                <p>
                  No Data Available. <br /> Please Play games to save your Stats
                </p>
              ) : (
                <>
                  <p>Score : {userDataMonthly?.data?.data?.score} </p>
                  <p className="m-0 p-0">
                    Rank : {userDataMonthly?.data?.data?.rank}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="col-md-6 col-12 mb-2">
          <div className="card game-stats-card  text-white">
            <div className="card-body">
              <h5> ALL TIME</h5>
              {userDataAllTime?.data?.data?.rank == undefined || 0 ? (
                <p>
                  No Data Available. <br /> Please Play games to save your Stats
                </p>
              ) : (
                <>
                  <p>Score : {userDataAllTime?.data?.data?.score} </p>
                  <p className="m-0 p-0">
                    Rank : {userDataAllTime?.data?.data?.rank}
                  </p>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-center flex-column mb-5">
        <div className="d-flex align-items-center gap-2">
          {showLogoutButton && (
            <div
              className="d-flex align-items-center w-100"
              style={{ cursor: "pointer" }}
            >
              <GoogleLogout
                clientId={settings.api.GOOGLE_CLIENT_ID}
                buttonText="Logout"
                onLogoutSuccess={logout}
                render={(renderProps) => (
                  <div
                    onClick={renderProps.onClick}
                    className="w-100 auth-action-btn"
                  >
                    Logout
                  </div>
                )}
              />
            </div>
          )}
          {!showLogoutButton && (
            <span
              className="w-100 auth-action-btn"
              style={{ cursor: "pointer" }}
              onClick={logout}
            >
              Logout
            </span>
          )}
        </div>
        <NavLink className="mt-3 text-center" to={Path.changePassword}>
          <span className="auth-action-btn">Change Password</span>
        </NavLink>
      </div>
    </div>
  );
};

export default Profile;
