import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Logo from "../../assets/icons/Logo.png";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { forgotPasswordFun } from "../../Services/authServices";
import { ToastContainer, toast } from "react-toastify";

const ForgotPassword = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const navigate = useNavigate();
  const onSubmit = (data: any) => {
    let params = {
      email: data.email,
    };
    forgotPasswordFun(params)
      .then((res: any) => {
        if (res.statusText === "OK") {
          toast.success(`Link to ${res.data.data}`, {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: 0,
            toastId: "my_toast",
          });
          setTimeout(() => {
            navigate("/");
          }, 4000);
        } else {
        }
      })
      .catch((error) => {
        toast.error(`${error.error}`, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: false,
          progress: 0,
          toastId: "my_toast",
        });
      });
  };

  return (
    <div className="auth-wrapper align-items-center bg-grey">
      <div className="row text-center justify-content-center">
        <div className="cards1 mb-5 align-middle">
          <div className="card-body">
            <img src={Logo} alt="Logo" width={65} className="py-3" />
            <p className="card-title3 mt-3">Forgot Password ??</p>
            <p className="card-title mt-3">
              The link to reset password will be sent to your registered email.
            </p>
            <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
              <div className="mb-3 row justify-content-center">
                <div className="col-sm-12">
                  <div className="input-group">
                    <input
                      type="email"
                      className="form-control shadow-none"
                      placeholder="Registered Email"
                      {...register("email", {
                        required: "Email is required",
                        pattern: {
                          value:
                            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                          message: "Invalid Email",
                        },
                      })}
                    />
                  </div>
                  {errors.email && (
                    <p className="errorss text-danger text-start ms-3 mt-1">
                      {errors.email.message}
                    </p>
                  )}
                </div>
              </div>
              <div className="mt-4 mb-4 row justify-content-center">
                <div className="col-sm-12">
                  <Button type="submit" variant="outline-light auth-btn btn-y">
                    Reset Password
                  </Button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={1500}
        hideProgressBar={true}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </div>
  );
};

export default ForgotPassword;
