import { useState } from "react";
import RegistrationBtn from "../Buttons/RegistrationBtn";
import Path from "../../Routes/RouterConstants";
import { useForm } from "react-hook-form";
import { Button, Modal } from "react-bootstrap";
import Logo from "../../assets/icons/Logo.png";
// OAuth Login Features
import { GoogleLogin } from "react-google-login";
import { refreshTokenSetup } from "../../QuizGame/SinglePlayer/Components/utils";
import { ToastContainer, toast } from "react-toastify";
import {
  facebookLoginFun,
  googleLoginFun,
  loginFun,
  registrationFun
} from "../../Services/authServices";
import { useDispatch, useSelector } from "react-redux";
import {
  clearToken,
  setUserToken,
  setUserId,
  clearId,
  setUserEmail,
  setPersonName
} from "../../helper/uitility";
import settings from "../../config/settings";
import FacebookLogin from "react-facebook-login";
import { ReactComponent as Eyeopen } from "../../assets/icons/Eye.svg";
import { ReactComponent as Eyeclose } from "../../assets/icons/Eyeclose.svg";
import { viewprofileData } from "../../Services/userProfileServices";
import userPic from "../../assets/images/userimage.png";
import Profile from "./Profile";
import { useQuery } from "react-query";

const Login = () => {
  const dispatch = useDispatch<any>();
  const [showLogoutButton, setShowLogoutButton] = useState(false);
  const reduxState = useSelector((state: any) => state);
  // login modal show & close
  const [loginModal, setLoginModal] = useState(false);
  const loginClose = () => {
    setLoginModal(false);
    loginFormReset();
  };
  const loginShow = () => {
    setLoginModal(true);
  };

  // Registration modal show and close
  const [regModal, setRegModal] = useState(false);
  const regClose = () => {
    setRegModal(false);
    registrationFormReset();
  };
  const regShow = () => {
    setRegModal(true);
  };

  const [passwordShown, setPasswordShown] = useState(false);
  const showPassword = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  // profile modal
  const [profileModal, setProfileModal] = useState(false);
  const profileClose = () => {
    setProfileModal(false);
  };

  const profileShow = () => setProfileModal(true);
  // form validation
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
    reset: loginFormReset
  } = useForm();

  const {
    register: userRegister,
    formState: { errors: signupError },
    handleSubmit: signupSubmit,
    reset: registrationFormReset
  } = useForm();

  // form on Submit ==== > API Call

  const onSubmit = (data: any) => {
    const params = {
      email: data.email,
      password: data.password
    };
    loginFun(params)
      .then((res: any) => {
        if (res.statusText === "OK") {
          dispatch({ type: "isLogin", userId: res.data[0].data.id });
          setLoginModal(false);
          setUserToken(res.data[0].data.token);
          setUserId(res.data[0].data.id);
          setUserEmail(res?.data[0]?.data?.email);
          setPersonName(res.data[0].data.name);
          setShowLogoutButton(false);
          setValue("email", "");
          setValue("password", "");
          toast.success(`Successfully Logged In as ${res.data[0].data.name}!`);
        }
      })
      .catch(function (error) {
        setLoginModal(false);
        toast.error(error.error);
      });
  };

  // Authorization functions
  const onLoginSuccess = (googleUser: any) => {
    const params = {
      access_token: googleUser.accessToken
    };
    googleLoginFun(params)
      .then((res: any) => {
        if (res.statusText === "OK") {
          dispatch({ type: "isLogin", userId: res.data.data.user_id });

          setUserToken(googleUser.accessToken);
          setShowLogoutButton(true);
          setUserId(res.data.data.user_id);
          setUserEmail(googleUser.profileObj.email);
          setPersonName(googleUser.profileObj.name);
          toast.success(
            `Successfully Logged In as ${googleUser.profileObj.name}!`
          );
        }
      })
      .catch(function (error: any) {
        clearToken();
        clearId();
        setShowLogoutButton(false);
        window.localStorage.clear();
        toast.error(`${error.error}`);
      });
    refreshTokenSetup(googleUser);
    setLoginModal(false);
    regClose();
  };

  // failure response true
  const onFailureSuccess = () => {
    setShowLogoutButton(false);
  };

  // Facebook Login
  const responseFacebook = (response: any) => {
    const imagedata = `${response.picture.data.url}?picture?type=large&width=300&height=300`;

    let params = {
      access_token: response.accessToken,
      email: response.email,
      profile_pic: response.picture.data.url
    };
    facebookLoginFun(params)
      .then((res: any) => {
        if (res.statusText === "OK") {
          dispatch({
            type: "isLogin",
            userId: res.data.data.user_id
          });
          setUserToken(res.data.data.token);
          setUserId(res.data.data.user_id);
          setUserEmail(response.email);
          setPersonName(response.name);
          toast.success(`Successfully Logged In as ${response.name}!`);
        }
      })
      .catch(function (error: any) {
        clearToken();
        clearId();
        setShowLogoutButton(false);
        window.localStorage.clear();
        toast.error(`${error.error}`);
      });
    setLoginModal(false);
    regClose();
  };

  // user registration
  const onRegistration = (data: any) => {
    const params = {
      name: data.name,
      email: data.email,
      password: data.password
    };
    registrationFun(params)
      .then((res: any) => {
        if (res.statusText === "OK") {
          toast.success("Registration Successfull");
          setValue("email", "");
          setValue("password", "");
          setValue("name", "");
        }
      })
      .catch(function (error) {
        toast.error("The Email has already been taken.");
      });
    regClose();
  };

  // get profile data
  const { data: getProfileData } = useQuery("getProfileData", viewprofileData, {
    enabled: reduxState.userReducer.isLogin,
    select: (data) => {
      if (
        typeof data?.data[0]?.data?.profile_pic === "string" &&
        data?.data[0]?.data?.profile_pic.slice(0, 5) === "image"
      ) {
        const imagePic = `http://admin.thepbnetwork.com/${data.data[0].data.profile_pic}`;
        return imagePic;
      }
      if (
        typeof data?.data[0]?.data?.profile_pic === "string" &&
        data?.data[0]?.data?.profile_pic.slice(0, 5) !== "image"
      ) {
        const imagePic = data.data[0].data.profile_pic;
        return imagePic;
      }
    }
  });

  const profilePic = localStorage.setItem("picture", getProfileData);

  // common logout funciton for facebook, google and Normal logout
  const logout = () => {
    clearToken();
    clearId();
    setShowLogoutButton(false);
    setProfileModal(false);
    dispatch({ type: "isLogout" });
    dispatch({ type: "resetFinalResultState" });
    window.localStorage.clear();
    toast.success("Successfully Logged Out");
  };

  return (
    <div className=" me-md-3 me-0">
      {/* auth buttons */}
      {!reduxState.userReducer.isLogin && (
        <div className="d-flex gap-2">
          <RegistrationBtn onClick={regShow}>Sign up</RegistrationBtn>
          <RegistrationBtn onClick={loginShow}>Login</RegistrationBtn>
        </div>
      )}
      {/* show image on header */}
      {reduxState.userReducer.isLogin && (
        <div className="d-flex align-items-center gap-2 ">
          <img
            src={getProfileData === undefined ? userPic : getProfileData}
            alt="pic"
            width={42}
            height={42}
            style={{ borderRadius: "50%", cursor: "pointer" }}
            onClick={profileShow}
          />
          {/* user Profile Modal and Details */}
          <Modal
            aria-labelledby="contained-modal-title-vcenter"
            centered
            show={profileModal}
            onHide={profileClose}
            size="lg"
            className="profile-modal"
          >
            <Modal.Body>
              <Profile
                logout={logout}
                profileClose={profileClose}
                showLogoutButton={showLogoutButton}
                getProfileData={getProfileData}
              />
            </Modal.Body>
          </Modal>
        </div>
      )}
      {/* Registration Modal */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={regModal}
        onHide={regClose}
      >
        <Modal.Body>
          <div className="row text-center  justify-content-center">
            <div className="cards1 pt-1 pb-1">
              <div className="card-body">
                <img src={Logo} alt="Logo" width={65} className="py-4" />
                <div className="d-flex flex-column align-items-center justify-content-center gap-3 mb-3 ">
                  <GoogleLogin
                    disabled={false}
                    clientId={settings.api.GOOGLE_CLIENT_ID}
                    buttonText="Signup with Google"
                    onSuccess={onLoginSuccess}
                    onFailure={onFailureSuccess}
                    cookiePolicy={"single_host_origin"}
                    isSignedIn={true}
                    className="google-login-btn"
                  />
                  <FacebookLogin
                    appId={settings.api.FACEBOOK_APP_ID}
                    autoLoad={false}
                    fields="name,email,picture"
                    scope="public_profile,email,user_friends"
                    callback={responseFacebook}
                    icon="fa-facebook"
                    onFailure={onFailureSuccess}
                    textButton="Signup with Facebook"
                  />
                </div>
                <form
                  className="row g-3"
                  autoComplete="off"
                  onSubmit={signupSubmit(onRegistration)}
                >
                  <div className="col-md-12">
                    <div className="input-group">
                      <input
                        className="form-control shadow-none"
                        type="text"
                        placeholder="Full Name"
                        {...userRegister("name", {
                          required: "Name is required",
                          pattern: {
                            value: /^[A-Za-z -]+$/,
                            message: "Only alphabets are allowed"
                          }
                        })}
                      />
                    </div>
                    {signupError.name && (
                      <p className="text-danger text-start  ms-3 mt-1  mb-0">
                        {signupError.name.message}
                      </p>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="input-group">
                      <input
                        type="email"
                        className="form-control shadow-none"
                        placeholder="Email"
                        {...userRegister("email", {
                          required: "Email is required",
                          pattern: {
                            value:
                              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                            message: "Invalid Email"
                          }
                        })}
                      />
                    </div>
                    {signupError.email && (
                      <p className="text-danger  text-start  ms-3 mt-1  mb-0">
                        {signupError.email.message}
                      </p>
                    )}
                  </div>
                  <div className="col-md-12">
                    <div className="input-group">
                      <input
                        placeholder="Password"
                        className="form-control shadow-none"
                        type={passwordShown ? "text" : "password"}
                        {...userRegister("password", {
                          required: "Password is required",
                          minLength: {
                            value: 8,
                            message: "Password must have atleast 8 characters"
                          }
                        })}
                      />
                      <button
                        className="eyebtn"
                        type="button"
                        onClick={showPassword}
                      >
                        {passwordShown ? <Eyeopen /> : <Eyeclose />}
                      </button>
                    </div>
                    {signupError.password && (
                      <p className="text-danger  text-start ms-3 mt-1  mb-0">
                        {signupError.password.message}
                      </p>
                    )}
                  </div>
                  <div className="mb-2 col-12 mt-4">
                    <Button
                      type="submit"
                      variant="outline-light auth-btn btn-y"
                    >
                      Sign Up
                    </Button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Login Modal */}
      <Modal
        aria-labelledby="contained-modal-title-vcenter"
        centered
        show={loginModal}
        onHide={loginClose}
      >
        <Modal.Body>
          <div className="auth-wrapper align-items-center">
            <div className="row text-center justify-content-center">
              <div className="cards1 mb-5 align-middle">
                <div className="card-body">
                  <img src={Logo} alt="Logo" width={65} className="py-3" />
                  <div className="my-3">
                    {/* google login */}
                    {!reduxState.userReducer.isLogin ? (
                      <GoogleLogin
                        clientId={settings.api.GOOGLE_CLIENT_ID}
                        buttonText="Login with Google"
                        onSuccess={onLoginSuccess}
                        onFailure={onFailureSuccess}
                        cookiePolicy={"single_host_origin"}
                        isSignedIn={true}
                        className="google-login-btn"
                        disabled={false}
                      />
                    ) : null}
                  </div>
                  {/* Facebook Login Features */}
                  <div className="my-3">
                    {!reduxState.userReducer.isLogin ? (
                      <FacebookLogin
                        appId={settings.api.FACEBOOK_APP_ID}
                        autoLoad={false}
                        fields="name,email,picture"
                        scope="public_profile,email,user_friends"
                        callback={responseFacebook}
                        icon="fa-facebook"
                        onFailure={onFailureSuccess}
                        isDisabled={
                          reduxState.userReducer.isLogin ? true : false
                        }
                      />
                    ) : null}
                  </div>

                  {/* Login form */}
                  <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
                    <div className="mb-3 row justify-content-center">
                      <div className="col-sm-12">
                        <div className="input-group">
                          <input
                            type="email"
                            className="form-control shadow-none"
                            placeholder="Email"
                            {...register("email", {
                              required: "Email is required",
                              pattern: {
                                value:
                                  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                message: "Invalid Email"
                              }
                            })}
                          />
                        </div>
                        {errors.email && (
                          <p className="errorss text-start text-danger  ms-3 mt-1  mb-0">
                            {errors.email.message}
                          </p>
                        )}
                      </div>
                    </div>
                    <div className="mb-3 row justify-content-center">
                      <div className="col-sm-12">
                        <div className="input-group">
                          <input
                            type={passwordShown ? "text" : "password"}
                            className="form-control shadow-none"
                            placeholder="Password"
                            {...register("password", {
                              required: "Password is required",
                              minLength: {
                                value: 8,
                                message:
                                  "Password must have atleast 8 characters"
                              }
                            })}
                          />
                          <button
                            className="eyebtn"
                            type="button"
                            onClick={showPassword}
                          >
                            {passwordShown ? <Eyeopen /> : <Eyeclose />}
                          </button>
                        </div>
                        {errors.password && (
                          <p className="text-danger text-start  ms-3 mt-1  mb-0">
                            {errors.password.message}
                          </p>
                        )}
                      </div>
                    </div>

                    <div className="mt-4 mb-4 row justify-content-center">
                      <div className="col-sm-12">
                        <Button
                          type="submit"
                          variant="outline-light auth-btn btn-y"
                        >
                          Login
                        </Button>
                      </div>
                    </div>

                    <p>
                      Forgot Password?{" "}
                      <a href={Path.forgotPassword} className="link">
                        Click Here
                      </a>
                    </p>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* Login Modal Close */}
      <ToastContainer
        position="top-right"
        autoClose={1000}
        hideProgressBar={true}
        newestOnTop={true}
        closeOnClick
        rtl={false}
        draggable
      />
    </div>
  );
};

export default Login;
