export const initialState = { finalResultState: false };

export function finalResult(state = initialState, action: { type: any; }) {
  switch (action.type) {
    case "finalResult":
      return { finalResultState: true };
    case "resetFinalResultState":
      return { finalResultState: false };
    default:
      return state;
  }
}
