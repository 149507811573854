export const initialState = { isLogin: false, userId: "", profileImage: "" };

export function userReducer(state = initialState, action) {
  switch (action.type) {
    case "isLogin":
      return {
        isLogin: true,
        userId: action.userId,
        profileImage: action.profileImage,
      };
    case "isLogout":
      return {
        isLogin: false,
        userId: "",
        profileImage: "",
      };
    default:
      return state;
  }
}
