import styled from "styled-components";

export const Wrapper = styled.div`
  max-width: 800px;
  border-radius: 0px 0px 20px 20px;
  p {
    width: 800px;
    font-family: Montserrat-Regular;
    padding: 20px 24px;
    color: #1d1d1d;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    background: #ffffff;
    border: 6px solid var(--blue);
    border-top: unset;
    border-radius: 0px 0px 40px 40px;
    min-height: 70px;
    text-align: center;

    @media (max-width: 992px) {
      width: 100%;
      padding: 16px 24px;
    }
    @media (max-width: 650px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      font-size: 20px;
      min-height: 60px;
    }

    @media (max-width: 568px) {
      min-width: 85vw;
    }
  }
`;

type ButtonWrapperProps = {
  correct: boolean;
  userClicked: boolean;
};

export const ButtonWrapper = styled.div<ButtonWrapperProps>`
  transition: all 0.3s ease;
  :hover {
    opacity: 0.8;
  }
  button {
    cursor: pointer;
    user-select: none;
    font-family: Montserrat-Regular;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    width: 100%;
    min-width: 500px;
    min-height: 70px;
    padding: 15px;
    margin: 5px 0;
    background: ${({ correct, userClicked }) =>
      correct ? "#53C536" : !correct && userClicked ? "#C54736" : "#367DC5"};
    border: 3px solid #ffffff;
    box-shadow: 1px 2px 0px rgba(0, 0, 0, 0.1);
    border-radius: 20px;
    color: #fff;
    text-shadow: 0px 1px 0px rgba(0, 0, 0, 0.25);
    @media (max-width: 650px) {
      min-width: 450px;
      font-size: 16px;
      font-weight: 500;
      line-height: 20px;
      padding: 12px;
      min-height: 60px;
    }
    @media (max-width: 568px) {
      min-width: 85vw;
    }
  }
  img {
    display: ${({ correct, userClicked }) =>
      correct ? "block" : !correct && userClicked ? "block" : "none"};
    color: white !important;
  }
`;
