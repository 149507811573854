import { useEffect, useState } from "react";
import VideoPlayer from "../../../Components/Player";

const HostPage = ({ hostVideo, nextComponent, hostName, playBtn, hideBtn, unmuteBtn, muteBtn }) => {
  const onEnded = () => {
    nextComponent("roundOneSnippet");
  };

  return (
    <div className="host-video-page d-flex flex-column align-items-center">
      <div className="player-wrapper">
        <VideoPlayer loop={false} url={hostVideo} onEnded={onEnded} playBtn={playBtn} hideBtn={hideBtn} muteBtn={muteBtn} unmuteBtn={unmuteBtn}/>
      </div>
      <p className="about-host py-3">
        Give a warm round of applause for your Host {hostName}!
      </p>
    </div>
  );
};

export default HostPage;
