export const shuffleArray = (array: any[]) =>
  [...array].sort(() => Math.random() - 0.5);

export const getQueryParams = function (data: string) {
  const queryParams = new URLSearchParams(window.location.search);
  return queryParams.get(data);
};

export const ifURLIncludes = (val: string) => {
  if (window.location.pathname.indexOf(val) > -1) {
    return true;
  } else return false;
};

export const refreshTokenSetup = (res: any) => {
  // timing to renew access token
  let refreshTiming = (res.tokenObj.expires_in || 3600 - 5 * 60) * 1000;
  const refreshToken = async () => {
    const newAuthRes = await res.reloadAuthResponse();
    refreshTiming = (newAuthRes.expires_in || 3600 - 5 * 60) * 1000;
    // setup other timer after first one
    setTimeout(refreshToken, refreshTiming);
  };
  // setup first refresh timer
  setTimeout(refreshToken, refreshTiming);
};
