import React, { useState, useEffect } from "react";
import { ReactComponent as CrossIcon } from "../../assets/icons/exit.svg";
import { ReactComponent as RefreshIcon } from "../../assets/icons/refresh.svg";
import Logo from "../../assets/icons/Logo.png";
// imports for quiz game
import { quizGameFun } from "../../Services/quizGameServices";
import HostPage from "./Components/HostPage";
import FirstRoundSnippet from "./Components/FirstRoundSnippet";
import ThirdRoundSnippet from "./Components/ThirdRoundSnippet";
import SecondRoundSnippet from "./Components/SecondRoundSnippet";
import RoundOneQues from "./Components/RoundOneQues";
import RoundTwoQues from "./Components/RoundTwoQues";
import RoundThreeQues from "./Components/RoundThreeQues";
import FinalResult from "./Components/FinalResult";
import { useDispatch } from "react-redux";
import { clearGameId } from "../../helper/uitility";
import { getQueryParams } from "./Components/utils";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../assets/icons/loading-video.gif";
// types for quiz
export type AnsObj = {
  question: string;
  answer: string;
  correct: boolean;
  correctAns: string;
};
// types for questions, answer, video link, game id, and options
export type Question = {
  question: string;
  correct_answer: string;
  option_one: string;
  option_two: string;
  option_three: string;
  id: number;
  video_code: string;
};
// ques state types
export type QuesState = Question & { answers: string[] };

// game props types for game id and close modal
type gameProps = {
  gameType?: any;
};

// main function for single player starts now
const QuizGame: React.FC<gameProps> = ({ gameType }) => {
  //  States for Quiz
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();
  const initial_url = window.location.href;
  const gameId = initial_url.split("/gameId/")[1];
  // const gameId = getQueryParams("gameId/");
  // const [gameId, setGameId] = useState<string | number| null>(null);
  const [score, setScore] = useState(0);
  const [timer, setTimer] = useState(0);
  const [timerVideo, setTimerVideo] = useState("");
  const [totalQues, setTotalQues] = useState(0);
  const [bgmusicState, setBgMusicState] = useState(true);
  const [loading, setLoading] = useState(true);

  // game rounds states
  const [roundOneQues, setRoundOneQues] = useState([]);
  const [roundTwoQues, setRoundTwoQues] = useState([]);
  const [roundThreeQues, setRoundThreeQues] = useState([]);

  // Game round starting video snippets
  const [roundOneVideo, setRoundOneVideo] = useState("");
  const [roundTwoVideo, setRoundTwoVideo] = useState("");
  const [roundThreeVideo, setRoundThreeVideo] = useState("");

  // Host Video and game trophy
  const [hostVideo, setHostVideo] = useState("");
  const [hostName, setHostName] = useState("");
  const [gameTrophyId, setGameTrophyId] = useState("");

  //set backgroumd music
  const [backgroundMusic, setBackgroundMusic] = useState("");
  const [timerId, setTimerId] = useState<ReturnType<typeof setTimeout>>(
    setTimeout(() => "", 1)
  );

  //next component triggers
  const [nextCompo, setNextComponent] = useState("hostVideo");
  // correct and wrong answers
  const [firstRoundCorrect, setFirstRoundCorrect] = useState(0);
  const [firstRoundWrong, setFirstRoundWrong] = useState(0);
  const [secondRoundCorrect, setSecondRoundCorrect] = useState(0);
  const [secondRoundWrong, setSecondRoundWrong] = useState(0);
  const [thirdRoundCorrect, setThirdRoundCorrect] = useState(0);
  const [thirdRoundWrong, setThirdRoundWrong] = useState(0);
  const [playBtn, setPlayBtn] = useState(true);
  const [muteBtn, setMuteBtn] = useState(true);

  function navigateToNewRoute(route: string) {
    window.location.href = route;
  }

  const hideBtn = (status: boolean) => {
    setPlayBtn(status);
  };
  const unmuteBtn = (status: boolean) => {
    setMuteBtn(status);
  };

  const handleClose = () => {
    setBgMusicState(false);
    dispatch({ type: "resetFinalResultState" });
    clearGameId();
    navigateToNewRoute("/");
  };

  // update score in score box
  const scoreData = (score: any) => {
    setScore(score);
  };

  // round 1 right and wrong answers
  const roundOneCorrect = (firstRoundCorrect: number) => {
    setFirstRoundCorrect(firstRoundCorrect);
  };
  const roundOneWrong = (firstRoundWrong: number) => {
    setFirstRoundWrong(firstRoundWrong);
  };

  // round 2 right and wrong answers
  const roundTwoCorrect = (secondRoundCorrect: any) => {
    setSecondRoundCorrect(secondRoundCorrect);
  };
  const roundTwoWrong = (secondRoundWrong: any) => {
    setSecondRoundWrong(secondRoundWrong);
  };

  // round 3 right and wrong answers
  const roundThreeCorrect = (thirdRoundCorrect: any) => {
    setThirdRoundCorrect(thirdRoundCorrect);
  };
  const roundThreeWrong = (thirdRoundWrong: any) => {
    setThirdRoundWrong(thirdRoundWrong);
  };

  // component that triggers next state automatically on condition match
  const nextComponent = (nextComponent: any) => {
    setNextComponent(nextComponent);
  };
  // timer show/hide toggle
  const [showTimer, setShowTimer] = useState(true);
  const clearCustomTimer = () => {
    clearTimeout(timerId);
    setTimeout(() => {
      setShowTimer(false);
    }, 3000);
  };

  // timer runs down function
  const timerFunction = (timer: any) => {
    if (timer > 0) {
      const time = setTimeout(() => setTimer(timer - 1), 1000);
      setTimerId(time);
      setTimer(timer);
    }
    if (score) {
      clearTimeout(timer);
    }
  };

  useEffect(() => {
    timerFunction(timer);
  }, [timer]);

  // Game API Call
  useEffect(() => {
    fetchGame();
  }, []);

  const fetchGame = async () => {
    const initial_url = window.location.href;
    const finalUrl = initial_url.split("/gameId/");
    await quizGameFun(finalUrl[1])
      .then((response) => {
        {
          if (response?.data?.data?.trophy !== null || undefined)
            setGameTrophyId(response?.data?.data?.trophy);
        }
        setRoundOneQues(response?.data?.data?.round_1_question);
        setRoundTwoQues(response?.data?.data?.round_2_question);
        setRoundThreeQues(response?.data?.data?.round_3_question);
        setRoundOneVideo(response?.data?.data?.round1_starting_video_snippet);
        setRoundTwoVideo(response?.data?.data?.round2_starting_video_snippet);
        setRoundThreeVideo(response?.data?.data?.round3_starting_video_snippet);
        setHostVideo(response?.data?.data?.host_video_snippet);
        setBackgroundMusic(response?.data?.data?.music_file);
        setHostName(response?.data?.data?.host);
        setTimerVideo(response?.data?.data?.time_down_video_snippet);
        setTotalQues(
          response?.data?.data?.round_1_question.length +
            response?.data?.data?.round_2_question.length +
            response?.data?.data?.round_3_question.length
        );
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Quiz Not Found!"
          // footer: '<a href="">Why do I have this issue?</a>'
        }).then((result) => {
          navigate(`/`);
        });
      });
  };

  const restartGame = () => {
    nextComponent("hostVideo");
    setScore(0);
    setFirstRoundCorrect(0);
    setFirstRoundWrong(0);
    setSecondRoundCorrect(0);
    setSecondRoundWrong(0);
    setThirdRoundCorrect(0);
    setThirdRoundWrong(0);
  };

  // if(loading){
  //   return <img
  //   className="vid-loader"
  //   src={Loader}
  //   alt="Loading..."
  //   width={60}
  // />
  // }

  // DOM return
  return (
    <div className="main-game-container bg-white">
      {/* game header starts here > it contains score and timer */}
      <div className="quiz-game-wrapper d-flex justify-content-between align-items-center px-md-5 px-sm-4 px-2">
        <div className="logo">
          <img src={Logo} alt="Logo" width={50} />
        </div>
        <div className="countdown-score d-flex gap-sm-3 gap-2">
          <div
            className={`timer-box ${
              nextCompo === "finalScorecard" ? "d-none" : ""
            }`}
          >
            <p> Timer </p>
            {showTimer && <h1>:{timer}</h1>}
            {!showTimer && <h1>:30</h1>}
          </div>
          <div className="scorecard-box">
            <p> Score</p>
            <h1 className="score">{score}</h1>
          </div>
        </div>
        <div className="action-btn d-flex align-items-center justify-content-around">
          <RefreshIcon onClick={restartGame} className="refresh-icon" />
          <CrossIcon onClick={handleClose} className="cross-icon" />
        </div>
      </div>

      {/* game container starts here */}
      <div className="game-backgroud d-flex row ">
        <div
          className={`game-container p-md-5 px-sm-4 py-3 px-3  d-flex align-items-center flex-column ${
            gameType === "Multi Player" ? "col-lg-8 col-md-12" : "w-100"
          }`}
        >
          {/* Game Starts here with the flow*/}
          <div className="game-quesbox text-white">
            <div className="quiz-game-container d-flex flex-column align-items-center mt-lg-1 mt-3">
              <div>
                {nextCompo === "hostVideo" ? (
                  <HostPage
                    nextComponent={nextComponent}
                    hostVideo={hostVideo}
                    hostName={hostName}
                    playBtn={playBtn}
                    hideBtn={hideBtn}
                    muteBtn={muteBtn}
                    unmuteBtn={unmuteBtn}
                  />
                ) : nextCompo === "roundOneSnippet" ? (
                  <FirstRoundSnippet
                    nextComponent={nextComponent}
                    roundOneVideo={roundOneVideo}
                    muteBtn={muteBtn}
                  />
                ) : nextCompo === "roundOne" ? (
                  <RoundOneQues
                    roundOneQues={roundOneQues}
                    scoreData={scoreData}
                    nextComponent={nextComponent}
                    timerFunction={timerFunction}
                    timerVideo={timerVideo}
                    roundOneCorrect={roundOneCorrect}
                    roundOneWrong={roundOneWrong}
                    clearCustomTimer={clearCustomTimer}
                    gameId={gameId}
                    backgroundMusic={backgroundMusic}
                    setShowTimer={setShowTimer}
                    setBgMusicState={setBgMusicState}
                    bgmusicState={bgmusicState}
                    muteBtn={muteBtn}
                  />
                ) : nextCompo === "roundTwoSnippet" ? (
                  <SecondRoundSnippet
                    nextComponent={nextComponent}
                    roundTwoVideo={roundTwoVideo}
                  />
                ) : nextCompo === "roundTwo" ? (
                  <RoundTwoQues
                    roundTwoQues={roundTwoQues}
                    scoreData={scoreData}
                    nextComponent={nextComponent}
                    timerFunction={timerFunction}
                    timerVideo={timerVideo}
                    clearCustomTimer={clearCustomTimer}
                    roundTwoCorrect={roundTwoCorrect}
                    roundTwoWrong={roundTwoWrong}
                    gameId={gameId}
                    backgroundMusic={backgroundMusic}
                    setShowTimer={setShowTimer}
                    setBgMusicState={setBgMusicState}
                    bgmusicState={bgmusicState}
                  />
                ) : nextCompo === "roundThreeSnippet" ? (
                  <ThirdRoundSnippet
                    nextComponent={nextComponent}
                    roundThreeVideo={roundThreeVideo}
                  />
                ) : nextCompo === "roundThree" ? (
                  <RoundThreeQues
                    roundThreeQues={roundThreeQues}
                    scoreData={scoreData}
                    nextComponent={nextComponent}
                    timerFunction={timerFunction}
                    timerVideo={timerVideo}
                    clearCustomTimer={clearCustomTimer}
                    roundThreeCorrect={roundThreeCorrect}
                    roundThreeWrong={roundThreeWrong}
                    gameId={gameId}
                    backgroundMusic={backgroundMusic}
                    setShowTimer={setShowTimer}
                    setBgMusicState={setBgMusicState}
                    bgmusicState={bgmusicState}
                  />
                ) : nextCompo === "finalScorecard" ? (
                  <FinalResult
                    score={score}
                    firstRoundCorrect={firstRoundCorrect}
                    secondRoundCorrect={secondRoundCorrect}
                    thirdRoundCorrect={thirdRoundCorrect}
                    firstRoundWrong={firstRoundWrong}
                    secondRoundWrong={secondRoundWrong}
                    thirdRoundWrong={thirdRoundWrong}
                    totalQues={totalQues}
                    gameId={gameId}
                    gameTrophyId={gameTrophyId}
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuizGame;
