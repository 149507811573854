import Api from "../config/Api";
import ApiInstance from "../config/Intercepter";

// open Game on Ckick
export function quizGameFun(id: any) {
  return ApiInstance.get(`${Api.quizGame}/${id}`);
}
// multiplayer game
export function multiplayerGameFun() {
  return ApiInstance.get(`${Api.multipayerGame}`);
}
// single player game
export function singlePlayerGameFun() {
  return ApiInstance.get(`${Api.singlePlayerGame}`);
}

// featured game
export function featuredGameFun() {
  return ApiInstance.get(`${Api.featuredGame}`);
}
// quiz game data
export function quizGameDataFun(payload: any) {
  return ApiInstance.post(`${Api.gameData}`, payload);
}
// user starts > particular stats
export function userGameDataFun() {
  return ApiInstance.get(`${Api.userGameData}`);
}
// user game cards data
export function userGameCardsDataFun() {
  return ApiInstance.get(`${Api.userGameCardsData}`);
}
// user trophy cards data
export function userTrophyCardsDataFun() {
  return ApiInstance.get(`${Api.userTrophyCardsData}`);
}
// earned trophies
export function earnedTrophiesFun() {
  return ApiInstance.get(`${Api.earnedTrophies}`);
}

// all users stats
export function allUserStatsFun() {
  return ApiInstance.get(`${Api.allUserStats}`);
}
// live score
export function liveScoreFun(liveUserScore: any) {
  return ApiInstance.post(`${Api.liveScore}`, liveUserScore);
}

// live score
export function getLiveScoreFun() {
  return ApiInstance.get(`${Api.getLiveScore}`);
}
