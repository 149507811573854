import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import Login from "../../../Components/Auth/Login";
import { clearGameId } from "../../../helper/uitility";
import { aboutTrophyFun } from "../../../Services/homePageServices";
import { useDispatch, useSelector } from "react-redux";
import {
  quizGameDataFun,
  singlePlayerGameFun
} from "../../../Services/quizGameServices";
import QuizGame from "../QuizGame";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

type trophyProps = {
  image: string;
  name: string;
};

const FinalResult = ({
  score,
  firstRoundCorrect,
  secondRoundCorrect,
  thirdRoundCorrect,
  firstRoundWrong,
  secondRoundWrong,
  thirdRoundWrong,
  totalQues,
  gameId,
  gameTrophyId
}) => {
  // won trophy data
  const dispatch = useDispatch<any>();
  const navigate = useNavigate();

  const reduxState = useSelector((state: any) => state);
  const [trophydata, setTrophyData] = useState<trophyProps>();
  const [saveScore, setSaveScore] = useState(false);
  // more games data
  const [games, setGames] = useState([]);
  const [gameID, setGameId] = useState("");
  const [bgmusicState, setBgMusicState] = useState(true);
  const [show, setShow] = useState(false);

  function navigateToNewRoute(route: string) {
    window.location.href = route;
  }

  const openGame = (id: string, host: string) => {
    if (saveScore) {
      dispatch({ type: "resetFinalResultState" });
      navigateToNewRoute(`/play-game/gameId/${id}`);
    }
  };

  const handleCloseGame = () => {
    if (saveScore) {
      setShow(false);
      setBgMusicState(false);
      clearGameId();
    }
  };

  // total correct ques
  const totalCorrect =
    firstRoundCorrect + secondRoundCorrect + thirdRoundCorrect;
  // total wrong answer
  const totalWrong = firstRoundWrong + secondRoundWrong + thirdRoundWrong;
  let gameDate = new Date();
  let gameTime =
    gameDate.getHours() +
    ":" +
    gameDate.getMinutes() +
    ":" +
    gameDate.getSeconds();

  // API Call to send data to backend

  const gameData = () => {
    let params = {
      user_id: reduxState.userReducer.userId,
      game_id: gameId,
      correct_answer: totalCorrect,
      incorrect_answer: totalWrong,
      total_questions: totalQues,
      total_score: score,
      game_date: gameDate,
      game_time: gameTime
    };
    quizGameDataFun(params)
      .then((res: any) => {
        if (res.statusText === "OK") {
          dispatch({ type: "finalResult" });
          localStorage.removeItem("ques");
          localStorage.removeItem("correct");
          setSaveScore(true);
          toast.success(
            "Score saved  successfully. You can now play another Game."
          );
        }
      })
      .catch(function (error) {});
  };
  // save score
  useEffect(() => {
    gameData();
  }, [reduxState.userReducer.isLogin]);
  // trophy details
  useEffect(() => {
    gameTrophy();
    fetchGames();
  }, []);

  // play more games API on final Page  API Call
  const fetchGames = () => {
    singlePlayerGameFun().then((res: any) => {
      setGames(res?.data?.data);
    });
  };

  const gameTrophy = () => {
    aboutTrophyFun(gameTrophyId).then((res: any) => {
      if (res.statusText === "OK") {
        setTrophyData(res?.data?.data);
      }
    });
  };

  return (
    <div className="container d-flex flex-column align-items-center justify-content-center">
      <div className="finalresult d-flex flex-column align-items-center gap-3 py-lg-5 py-sm-4 py-3">
        <img
          className="won-trophy-image"
          src={trophydata?.image}
          alt={trophydata?.name}
          width={100}
        />
        <h1 className="w-100">CONGRATULATIONS!!</h1>
        <p className="m-0 p-0 text-center">
          You have earned the {trophydata?.name}!!✌
        </p>
        <h4 className="m-0 p-0">You Answered Correctly</h4>
        <p className="m-0 p-0">
          {totalCorrect} out of {totalQues}
        </p>
        {!reduxState.userReducer.isLogin && (
          <div
            className={`d-flex align-items-center flex-column ${
              reduxState.userReducer.isLogin
                ? "d-none"
                : "d-block card br-20 p-4"
            }`}
          >
            <p className="text-danger p-0">
              Login or Register to save your Score.
            </p>
            <Login />
          </div>
        )}
      </div>
      <p className="hostname text-center text-light my-md-4 my-3">
        Play Another Game
      </p>

      {/*play more games */}
      <div className="d-flex flex-wrap align-items-center justify-content-center">
        {games.map((item: any, key: any) => {
          return (
            <div
              key={item.id}
              className="d-flex flex-column align-items-center ms-0"
            >
              <div className="card text-white sec-card allgame-card">
                <img
                  key={key + 1}
                  src={item.image}
                  onClick={() => openGame(item.id, item.host)}
                  className="card-img"
                  alt="..."
                  style={{ objectFit: "cover" }}
                />
              </div>
              <div>
                <h5 className="image-title text-center gamename text-light">
                  {item.name}
                </h5>
                <h6 className="hostname text-center text-light">
                  Host : {item.host}
                </h6>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FinalResult;
