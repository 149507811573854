import React from "react";
export const development = {
  api: {
    url: "https://admin.thepbnetwork.com/api/",
    mode: "cors",
    GOOGLE_CLIENT_ID:
      "1048631118017-32a0nrhqarr7fv6cq48jeh0k98l3u1d4.apps.googleusercontent.com",
    FACEBOOK_APP_ID: "1905006966360940",
    GOOGLE_ANALYTICS_ID: "G-K6THBD1YD4",
    REACT_APP_MAILCHIMP_U: "f150ff04a774733acfcb76d22",
    REACT_APP_MAILCHIMP_ID: "4b4f95a89d"
  }
};

export const staging = {
  api: {
    url: "http://admin.thepbnetwork.com/api/",
    mode: "cors",
    GOOGLE_CLIENT_ID:
      "1048631118017-32a0nrhqarr7fv6cq48jeh0k98l3u1d4.apps.googleusercontent.com",
    FACEBOOK_APP_ID: "1905006966360940",
    GOOGLE_ANALYTICS_ID: "G-K6THBD1YD4",
    REACT_APP_MAILCHIMP_U: "f150ff04a774733acfcb76d22",
    REACT_APP_MAILCHIMP_ID: "4b4f95a89d"
  }
};
