export const initialState = { userAdded: false };

export function userChatEvent(state = initialState, action: { type: any }) {
  switch (action.type) {
    case "userAdded":
      return {
        userAdded: true,
      };
    case "userRemoved":
      return {
        userAdded: false,
      };
    default:
      return state;
  }
}
