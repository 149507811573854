import { createStore, applyMiddleware } from "redux";
import thunkMiddleware from "redux-thunk";
import { createLogger } from "redux-logger";
import rootReducer from "./_reducers";

const loggerMiddleware = createLogger();

function saveToLocalStorage(store) {
  try {
    const serializedStore = JSON.stringify(store);
    window.localStorage.setItem("_preset", serializedStore);
  } catch (e) {}
}

function loadFromLocalStorage() {
  try {
    const serializedStore = window.localStorage.getItem("_preset");
    if (serializedStore === null) return undefined;
    return JSON.parse(serializedStore);
  } catch (e) {
    return undefined;
  }
}

const persistedState = loadFromLocalStorage();

const store = createStore(
  rootReducer,
  persistedState,
  applyMiddleware(thunkMiddleware, loggerMiddleware)
);

store.subscribe(() => saveToLocalStorage(store.getState()));

export default store;
