import React from "react";
import ReactPlayer from "react-player";
import VideoPlayer from "../../../Components/Player";

const FirstRoundSnippet = ({ roundOneVideo, nextComponent, muteBtn }) => {
  const roundOneQuiz = () => {
    nextComponent("roundOne");
  };
  return (
    <div className="round-snippet d-flex flex-column align-items-center">
      <div className="player-wrapper">
        <VideoPlayer
          loop={false}
          url={roundOneVideo}
          onEnded={roundOneQuiz}
          muteBtn={muteBtn}
        />
      </div>
      <h1 className="w-100">Round 1</h1>
    </div>
  );
};

export default FirstRoundSnippet;
