import Api from "../config/Api";
import ApiInstance from "../config/Intercepter";

export function googleLoginFun(payload: any) {
  return ApiInstance.post(`${Api.googleLogin}`, payload);
}

export function facebookLoginFun(payload: any) {
  return ApiInstance.post(`${Api.facebookLogin}`, payload);
}

export function loginFun(payload: any) {
  return ApiInstance.post(`${Api.login}`, payload);
}

export function registrationFun(payload: any) {
  return ApiInstance.post(`${Api.registration}`, payload);
}
export function changePasswordFun(payload: any) {
  return ApiInstance.post(`${Api.changePassword}`, payload);
}
export function forgotPasswordFun(payload: any) {
  return ApiInstance.post(`${Api.forgetPassword}`, payload);
}
export function resetPasswordFun(payload: any) {
  return ApiInstance.post(`${Api.resetPassword}`, payload);
}
