import { Routes, Route } from "react-router-dom";
import Layout from "../Layout/Layout";
import QuizGame from "../QuizGame/SinglePlayer/QuizGame";

const PublicRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Layout />} />
      <Route path="play-game/gameId/:id" element={<QuizGame />} />
    </Routes>
  );
};

export default PublicRoutes;
