import Api from "../config/Api";
import ApiInstance from "../config/Intercepter";

export function allTimeLeadersboardFun() {
  return ApiInstance.get(`${Api.allTimeLeadersboard}`);
}

export function monthlyLeadersboardFun() {
  return ApiInstance.get(`${Api.monthlyLeadersboard}`);
}
export function weeklyLeadersboardFun() {
  return ApiInstance.get(`${Api.weeklyLeadersboard}`);
}
export function todayLeadersboardFun() {
  return ApiInstance.get(`${Api.todayLeadersboard}`);
}

// user Rank in Leadersboard services
export function userRankTodayFun(id: any) {
  return ApiInstance.get(`${Api.userRankToday}/${id}`);
}
export function userRankWeeklyFun(id: any) {
  return ApiInstance.get(`${Api.userRankWeekly}/${id}`);
}
export function userRankMonthlyFun(id: any) {
  return ApiInstance.get(`${Api.userRankMonthly}/${id}`);
}
export function userRankAllTimeFun(id: any) {
  return ApiInstance.get(`${Api.userRankAllTime}/${id}`);
}
