export function getToken() {
  let token = localStorage.getItem("token");
  if (token) {
    return true;
  } else {
    return false;
  }
}

export function setUserDetail(userDetail: any) {
  localStorage.setItem("userDetail", userDetail);
}

export function setUserToken(token: any) {
  if (token !== undefined) {
    localStorage.setItem("token", token);
  } else {
  }
}

export function getUserToken() {
  return localStorage.getItem("token");
}

export function clearToken() {
  localStorage.removeItem("token");
}

// set user ID
export function setUserId(id: any) {
  if (id !== undefined) {
    localStorage.setItem("userid", id);
  } else {
  }
}
export function getUserId() {
  return localStorage.getItem("userid");
}
export function getId() {
  let id = localStorage.getItem("userid");
  if (id) {
    return id;
  } else {
  }
}
export function clearId() {
  localStorage.removeItem("userid");
}
// set game ID
export function setGameId(gameID: any) {
  if (gameID !== undefined) {
    localStorage.setItem("gameId", gameID);
  } else {
  }
}
export function getGameId() {
  return localStorage.getItem("gameId");
}
export function getGameroomId() {
  let gameID = localStorage.getItem("gameId");
  if (gameID) {
    return gameID;
  } else {
  }
}
export function clearGameId() {
  localStorage.removeItem("gameId");
}
// set game score
export function setUserEmail(email: any) {
  if (email !== undefined) {
    localStorage.setItem("email", email);
  } else {
  }
}
export function getUserEmail() {
  return localStorage.getItem("email");
}

// set user name
export function setPersonName(name: any) {
  if (name !== undefined) {
    localStorage.setItem("name", name);
  } else {
  }
}
export function getPersonName() {
  return localStorage.getItem("name");
}
